import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import IrelandMap from "./irelandMap"

const Contact = (props) => (
    <Layout>
        <Helmet>
            <title>Contact</title>
            <meta name="description" content="Contact Page" />
        </Helmet>

        <div id="main" className="alt">
            <section id="one">
                <div className="inner">
                    <header className="major">
                        <h3>Contact Marcon Tanks</h3>
                    </header>
                    <IrelandMap />
                </div>
            </section>
        </div>

    </Layout>
)

export default Contact